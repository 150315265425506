import axios from 'axios';
import { apiNotifyMentionedUsers, fetchUsersList } from '@/offers/api/dashboard';

const protocol = window.location.protocol + '//';
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-unused-vars
const split = window.location.host.split(':');
const newHost = split.length > 1 ? split[0] + ':8000' : split[0];
const urlBase = protocol + newHost;

const state = {
  users: [],
  user: {},
  areUsersLoading: false,
  tokenFail: false,
  userComponentPermissions: [],
  allComponentPermissions: [],
  passwordResetEmail: '',
  passwordResetToken: '',
  newPassword: '',
};

const getters = {
  users: (state) => {
    return state.users;
  },
  user: (state) => {
    return state.user;
  },
  areUsersLoading: (state) => state.areUsersLoading,
  getComponentPermissions: (state) => {
    return state.userComponentPermissions;
  },
  getAllComponentPermissions: (state) => {
    return state.allComponentPermissions;
  },
  getPasswordResetEmail: (state) => {
    return state.passwordResetEmail;
  },
  getPasswordResetToken: (state) => {
    return state.passwordResetToken;
  },
  getNewPassword: (state) => {
    return state.newPassword;
  },
  getTokenFail: (state) => {
    return state.tokenFail;
  },
};

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  setLoading(state, loading) {
    state.areUsersLoading = loading;
  },
  setUser(state, user) {
    state.user = user;
  },
  setEmailFail(state, bool) {
    state.emailFail = bool;
  },
  setTokenFail(state, bool) {
    state.tokenFail = bool;
  },
  setComponentPermissions(state, permissions) {
    state.userComponentPermissions = permissions;
  },
  setAllComponentPermissions(state, permissions) {
    state.allComponentPermissions = permissions;
  },

  deleteUser(state, userId) {
    state.users = state.users.filter(({ id }) => id !== userId);
  },
  deleteComponentPermission(state, componentPermissionId) {
    state.userComponentPermissions = state.userComponentPermissions.filter(
      (permission) => permission.id !== componentPermissionId
    );

    state.allComponentPermissions = state.allComponentPermissions.filter(
      (permission) => permission.id !== componentPermissionId
    );
  },
  setPasswordResetEmail(state, email) {
    state.passwordResetEmail = email;
  },
  setPasswordResetToken(state, token) {
    state.passwordResetToken = token;
  },
  setNewPassword(state, password) {
    state.newPassword = password;
  },
};

const actions = {
  getUsersList(context) {
    context.commit('setLoading', true);
    fetchUsersList()
      .then((response) => {
        context.commit('setUsers', response.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        context.commit('setLoading', false);
      });
  },
  getUsersAndPermissions({ dispatch }) {
    return Promise.all([dispatch('getUsersList'), dispatch('fetchGeoPermissions'), dispatch('fetchLevelPermissions')]);
  },
  setUser(context, user) {
    context.commit('setUser', user);
  },
  getUser(context, userId) {
    return axios
      .get(urlBase + '/api/users/' + userId)
      .then((response) => {
        context.commit('setUser', response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  createUser(context, payload) {
    const avatar = payload.avatar;
    delete payload.avatar;

    return axios
      .post(urlBase + '/api/users/', payload)
      .then((response) => {
        // Image upload
        if (typeof avatar === 'object') {
          const data = new FormData();
          data.append('avatar', avatar);
          return axios.patch(urlBase + '/api/users/' + response.data.id, data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },
  async patchUser(context, payload) {
    const { id, data } = payload;

    try {
      const response = await axios.patch(`${urlBase}/api/users/${id}`, data);
      context.dispatch('getUsersList');
      return response;
    } catch (error) {
      console.log(error);
    }
  },
  deleteUser({ commit }, userId) {
    return axios
      .delete(urlBase + '/api/users/' + userId)
      .then(() => {
        commit('deleteUser', userId);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  tokenValidate(context, token) {
    return axios.post(urlBase + '/api/password_reset/validate_token/', token).then((response) => {
      context.commit('setTokenFail', false);
      context.commit('setPasswordResetToken', token);
    });
    // I catch this in the component, don't freak, Milos!
  },
  passwordReset(context, payload) {
    return axios
      .post(urlBase + '/api/password_reset/confirm/', payload)
      .then((response) => {
        context.commit('setPasswordResetToken', payload.token);
        context.commit('setNewPassword', payload.password);
      })
      .catch((e) => {
        // context.commit('setTokenFail', true)
        const message = e.response.data.password ?? [e.response.data.detail];
        throw new Error(Object.values(message).join(' '));
      });
  },
  passwordChange(context, payload) {
    return axios
      .post(urlBase + '/api/users/password_change/', payload)
      .then((response) => {
        context.commit('setTokenFail', false);
      })
      .catch((e) => {
        context.commit('setTokenFail', true);
        const message = e.response.data.new_password;
        throw new Error(Object.values(message).join(' '));
      });
  },
  async emailChange(context, payload) {
    const { data } = payload;
    return axios.post(`${urlBase}/api/users/email_change`, data);
  },
  async deleteSelf(context, payload) {
    return axios.post(`${urlBase}/api/users/delete_self`, payload);
  },
  async fetchUserComponentPermissions({ commit }, userId, cycleId) {
    try {
      const { data } = await axios.get(`${urlBase}/api/component-permissions/?user=${userId}&comp_cycle=${cycleId}`);

      commit('setComponentPermissions', data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchAllComponentPermissions({ commit }) {
    try {
      const { data } = await axios.get(`${urlBase}/api/component-permissions`);
      commit('setAllComponentPermissions', data);
    } catch (error) {
      console.log(error);
    }
  },
  async createUpdateComponentPermissions({ commit, dispatch }, payload) {
    try {
      await axios.post(`${urlBase}/api/component-permissions/`, payload);

      dispatch('fetchAllComponentPermissions');
    } catch (error) {
      console.log(error);
    }
  },
  async createDefaultManagerPerms({ commit, dispatch }, payload) {
    try {
      await axios.post(`${urlBase}/api/component-permissions/default_manager_permissions`, payload);
      dispatch('fetchAllComponentPermissions');
    } catch (error) {
      console.error('Failed to create default component permission for manager(s)', error);
    }
  },
  async bulkDeleteComponentPermissions({ commit, dispatch }, payload) {
    try {
      await axios.delete(`${urlBase}/api/component-permissions/delete-permissions`, payload);

      dispatch('fetchAllComponentPermissions');
    } catch (error) {
      console.log(error);
    }
  },
  async deleteComponentPermission({ commit }, payload) {
    try {
      await axios.delete(`${urlBase}/api/component-permissions/` + `${payload.id}`);

      commit('deleteComponentPermission', payload.id);
    } catch (error) {
      console.log(error);
    }
  },
  async notifyMentionedUsers(_, payload) {
    try {
      await apiNotifyMentionedUsers(payload);
    } catch (error) {
      console.log(error);
    }
  },
  async updateUserRole({ commit, state }, { id, payload }) {
    try {
      const { data: user } = await axios.patch(`${urlBase}/api/users/${id}/role`, payload);
      commit(
        'setUsers',
        state.users.map((u) => (u.id === user.id ? user : u))
      );
    } catch (e) {
      console.error('Failed to update user role!', e);
    }
  },
  async updateUserPermissions({ commit, state }, { id, payload }) {
    try {
      const { data: user } = await axios.post(`${urlBase}/api/users/${id}/permissions/`, payload);
      commit(
        'setUsers',
        state.users.map((u) => (u.id === user.id ? user : u))
      );
    } catch (error) {
      console.error('Failed to update user permissions!', error);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
